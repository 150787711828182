<!--
 * @Descripttion: 
 * @Author: 银河以北
 * @Date: 2024-04-09 16:19:25
 * @LastEditors: 银河以北
 * @LastEditTime: 2024-04-09 18:12:55
-->
<template>
    <div @click="emitData()">
        {{ message }}
        <slot name="ljk" testProps="子组件的值"> </slot>
        <!-- <Children2 v-on="$listeners"></Children2> -->
    </div>
</template>

<script>
    // import Children2 from '@/components/test/Children2/index.vue'
    export default {
        props: {
            // 字符串形式  
            name: String, // 接收的类型参数  
            // 对象形式  
            age: {
                type: Number, // 接收的类型为数值  
                defaule: 18, // 默认值为18  
                require: true // age属性必须传递  
            }
        },
        data() {
            return {
                message: '子组件1',


            }
        },
        // components: {
        //     Children2
        // },
        created() {


        },
        methods: {
            emitData() {
                // console.log(this.$parent)
                // this.$parent.emit('add')
                // this.$utils.bus.$emit('test', this.message)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>