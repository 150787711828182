<!--
 * @Descripttion: 
 * @Author: 银河以北
 * @Date: 2023-01-06 20:14:38
 * @LastEditors: 银河以北
 * @LastEditTime: 2024-04-09 22:17:26
-->
<template>
  <div class="app-contaner">
    <input type="text" v-model.lazy="value">
    <p>{{value | capitalize }}</p>
    <!-- nexttick -->
    <!-- 
    <p ref="msg">{{ msg }}</p>
    <el-button @click="comMethod1()">testNexttck</el-button> -->

    <!-- v-model -->
    <!-- <input v-model="msg" type="text">
    <input :value="msg" @input="changeData($event)" type="text"> -->

    <!-- 组件通信 -->
    <!-- <Children1 ref="children1" name="jack" :age=18 @emitInfo="getInfo"><template
        #ljk="slotProps">{{slotProps.testProps  }}</template></Children1> -->
    <!-- <Children2 :style-object="childrenStyle"></Children2> -->
    <!-- <el-button @click="testData()">事件总线</el-button> -->
  </div>
</template>

<script>
  import Children1 from '@/components/test/Children1/index.vue'
  // import Children2 from '@/components/test/Children2/index.vue'
  import MixinTest from '@/mixin/test'
  export default {
    data() {
      return {
        childrenStyle: {
          color: 'red',
          fontSize: '20px'
        },
        msg: '121',
        num: 1,
        value: 'a'
      }
    },
    // mixins: [MixinTest],
    components: {
      Children1,
      // Children2
    },
    filters: {
      capitalize(value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },
    created() {

    },
    methods: {
      comMethod1() {

        // this.msg = this.msg + this.num
        // this.num++
        // this.$nextTick(() => {
        //   console.log(this.$refs.msg.textContent)
        // })
        console.log(this.$refs.msg.textContent)

      },
      changeData($event) {
        this.msg = $event.target.value
        console.log($event)
      },
      emitData(data) {
        console.log(data)
      },
      testData() {
        console.log(this.$refs.children1.emitData())
      },
      getInfo(data) {
        console.log(data, 'xxx')
        //这是孙组件发送的信息
      }

    }
  }
</script>

<style lang="scss" scoped>

</style>